<template>
  <div>
    <b-row class="d-flex justify-content-center mt-3">
      <b-col
        md="12"
        lg="10"
      >
        <b-card>
          <b-form-file
            v-model="file"
            class="my-1"
            accept=".csv"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
          <b-card v-if="statisticInfo" class="card-info border" style="border-radius:4px; background-color: #28c76f;">
            <div class="text-center">
              <b>
                {{ statisticInfo }}
              </b>
            </div>
          </b-card>
          <template v-for="errorGroup in errorGroupList">
            <template
              v-for="(errorItem, errorItemKey) in errorGroup"
            >
              <div
                v-if="Array.isArray(errorItem)"
                :key="errorItem + errorItemKey"
              >
                <template
                  v-for="(errorItemMessage, errorItemMessageKey) in errorItem"
                >
                  <p
                    :key="errorItemMessageKey"
                    class="mt-2 text-danger"
                  >
                    {{ errorItemMessage }}
                  </p>
                </template>
              </div>
              <div
                v-else
                :key="errorItemKey"
              >
                <p
                  class="mt-2 text-danger"
                >
                  {{ errorItem }}
                </p>
              </div>
            </template>
          </template>
          <b-link
            v-if="$can('import', 'hardware')"
            href="/files/hardware.csv"
            download
          >
            Download CSV example
          </b-link>
          <b-button
            class="mt-1"
            variant="outline-primary"
            block
            @click="saveFile"
          >
            Import
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton, BFormFile, BRow, BCol, BCard, BLink,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton, BFormFile, BRow, BCol, BCard, BLink,
  },
  data() {
    return {
      file: null,
      statisticInfo: null,
      errorGroupList: [],
    }
  },
  methods: {
    ...mapActions('hardware', [
      'sendFile',
    ]),
    async saveFile() {
      this.statisticInfo = null
      this.errorGroupList = []
      try {
        const formData = new FormData()
        formData.append('file', this.file)
        await this.sendFile(formData)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        if (Object.keys(error.response.data.fields).length > 0) {
          const errors = []
          Object.keys(error.response.data.fields).forEach(key => {
            if (key === 'total_statistic') {
              this.statisticInfo = error.response.data.fields[key].length ? error.response.data.fields[key][0] : null
            } else {
              errors.push(error.response.data.fields[key])
            }
          })

          this.errorGroupList = errors
        }
        if (this.errorGroupList) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
